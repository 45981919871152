.MuiInputBase-input  {
    background: white;
    border: 1px solid #747474;
    border-radius: 5px;
  }
  
  .MuiTextField-root {
    background: white;
    border: 1px solid #747474;
    border-radius: 5px;
  }

  
  div {
    white-space: pre-wrap;
  } 

  #model1::-webkit-scrollbar  {
      display: none;
  }

  #model2::-webkit-scrollbar  {
    display: none;
}